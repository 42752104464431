
import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

export default defineComponent({
  name: "assign_role",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      obj: {
        entity_type_id: "",
        id: "",
      },
      entity: {
        entity_type_id: "",
        entity_type_role_id: "",
        user_id: "",
        entity_info_id: "",
        institute_info_id: "",
        resource_type: "",
        resource_id: "",
      },
      assessor: {
        highest_level_of_education: "",
        year_of_experience: "",
        is_certified_assessor: "1",
        assessor_body_name: "",
        area_of_expertise: "",
        list_of_certification: "",
        user_id: "",
      },
      trainer: {
        bteb_registration_number: "",
        highest_level_of_education: "",
        year_of_experience: "",
        area_of_expertise: "",
        list_of_certification: "",
        user_id: "",
      },
      entityId: "",
      entityRoles: [],
      entityInfos: [],
      institutes: [],
      loading: false,
    };
  },
  async created() {
    await this.getEntityInfos();
    this.emitter.on("assign_data", async (data) => {
      this.entity.user_id = data.employee.user_id;
      this.assessor.user_id = data.employee.user_id;
      this.trainer.user_id = data.employee.user_id;
    });
  },
  methods: {
    async submit() {
      this.loading = true;
      let url =
        this.entity.resource_type == "assessor"
          ? "resource/assessor"
          : "resource/trainer";
      let data =
        this.entity.resource_type == "assessor" ? this.assessor : this.trainer;
      await ApiService.post(url, data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.entity.resource_id = response.data.id;
            this.mapping();
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Error",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
    async mapping() {
      this.loading = true;
      await ApiService.post("mapping/userEntityRoleMapping", this.entity)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              text: response.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Error",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      await ApiService.get("entity/trainingInstitutes/" + this.entityId)
        .then((response) => {
          this.institutes = response.data;
          this.obj = {
            entity_type_id: "",
            id: "",
          };
          this.entity.entity_type_role_id = "";
          this.entityRoles = [];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getRoles() {
      this.entity.entity_type_id = this.obj.entity_type_id;
      this.entity.entity_info_id = this.entityId;
      this.entity.institute_info_id = this.obj.id;
      await ApiService.get(
        "entity/type_roles?entity_type=" + this.entity.entity_type_id
      )
        .then((response) => {
          this.entityRoles = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const Schema = Yup.object().shape({
      experience: Yup.string().required().label("Experience"),
      //assessor_body_name: Yup.string().required().label("Assessor Body Name"),
      bteb_registration_number: Yup.string()
        .required()
        .label("BTEB Registration Number"),
    });
    return {
      Schema,
    };
  },
});
