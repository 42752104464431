
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AssignEntityModal from "@/components/modals/forms/association/AssignEntityModal.vue";
import AssignInstituteModal from "@/components/modals/forms/association/AssignInstituteModal.vue";
import AssignResource from "@/components/modals/forms/association/AssignResource.vue";
import ChangePasswordModal from "@/components/modals/forms/association/ChangePasswordModal.vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "profile",
  components: {
    Datatable,
    AssignEntityModal,
    AssignInstituteModal,
    AssignResource,
    ChangePasswordModal,
  },
  setup() {
    const route = useRoute();
    const employeeID = route.params.id;
    const entityID = route.params.entity;
    return { employeeID, entityID };
  },
  data() {
    return {
      tableHeader: [
        {
          name: "Role",
          key: "role",
          sortable: true,
        },
        {
          name: "Training Partner",
          key: "training_partner",
          sortable: true,
        },
        {
          name: "Training Institute",
          key: "training_institute",
          sortable: true,
        },
        {
          name: "Active?",
          key: "active",
          sortable: false,
        },
        {
          name: "Action",
          key: "actions",
          sortable: false,
        },
      ],
      employee: {
        user_id: "",
        user: {
          active_status: 1,
        },
        roles: [],
      },
      search: "",
      tableData: [],
    };
  },
  async created() {
    await this.getEmployee();
    Object.assign(this.tableData, this.employee.roles);
  },
  methods: {
    async getEmployee() {
      await ApiService.get("employee/lists/" + this.employeeID)
        .then((response) => {
          this.employee = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // Delete() {
    //   Swal.fire({
    //     title: "Are you sure you want to delete this resource?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete!",
    //   });
    // },
    Deactivate() {
      Swal.fire({
        title: "Are you sure you want to deactivate this resource?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, deactivate!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = {
            user_id: this.employee.user_id,
            active_status: 0,
          };
          await ApiService.post("employee/changeActiveStatus", data)
            .then((response) => {
              if (response.status == 200) {
                this.getEmployee();
                Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  title: "Error",
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Unknown error",
                html: response.data.error,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
              console.log(response);
            });
        }
      });
    },

    RoleStatus(id, type) {
      const status = type == 1 ? "activate" : "deactivate";
      Swal.fire({
        title: "Are you sure you want to " + status + " this role?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, " + status + "!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = {
            id: id,
            active_status: type,
          };
          await ApiService.post("employee/changeRoleActiveStatus", data)
            .then(async (response) => {
              if (response.status == 200) {
                await this.getEmployee();
                Object.assign(this.tableData, this.employee.roles);
                Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  title: "Error",
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Unknown error",
                html: response.data.error,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
              console.log(response);
            });
        }
      });
    },

    DeleteRole(id) {
      Swal.fire({
        title: "Are you sure you want to delete this role?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ApiService.delete("employee/deleteRole/" + id)
            .then(async (response) => {
              if (response.status == 200) {
                await this.getEmployee();
                Object.assign(this.tableData, this.employee.roles);
                Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  title: "Error",
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Unknown error",
                html: response.data.error,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
              console.log(response);
            });
        }
      });
    },

    Activate() {
      Swal.fire({
        title: "Are you sure you want to activate this resource?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, activate!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = {
            user_id: this.employee.user_id,
            active_status: 1,
          };
          await ApiService.post("employee/changeActiveStatus", data)
            .then((response) => {
              if (response.status == 200) {
                this.getEmployee();
                Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  title: "Error",
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Unknown error",
                html: response.data.error,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
              console.log(response);
            });
        }
      });
    },
    employeeData() {
      this.emitter.emit("assign_data", {
        employee: this.employee,
        entity: this.entityID,
      });
    },
    changePassword(data) {
      this.emitter.emit("user-data", data);
    },
  },
});
