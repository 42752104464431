
import ApiService from "@/core/services/ApiService";
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

export default defineComponent({
  name: "password_change",
  components: {
    Field,
    ErrorMessage,
    Form,
  },

  data() {
    return {
      user: {
        username: "",
        password: "",
        password_confirmation: "",
        user_id: "",
      },
      loading: false,
    };
  },
  created() {
    this.emitter.on("user-data", async (data) => {
      this.user.username = data.email;
      this.user.user_id = data.user_id;
    });
  },
  methods: {
    async submit() {
      this.loading = true;
      await ApiService.post("employee/changePassword", this.user)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              text: response.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Error",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const Schema = Yup.object().shape({
      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      Schema,
    };
  },
});
